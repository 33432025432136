.Toastify__toast {
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-size: 0.7em;
  min-height: 1.5rem;
  button.Toastify__close-button.Toastify__close-button--success {
    margin-top: 0;
    padding: 8px;
    box-shadow: 0 0 transparent;
  }
  &.Toastify__toast--success {
    background-color: theme-color("green");
  }
}
