footer {
  padding: 4.5% 0 3%;
  font-size: 0.8em;
  color: theme-color(body-color);
  bottom: 0;
  background-color: theme-color(altBackground);
  width: 100%;
  .row {
    margin-bottom: 0;
  }
  a {
    color: theme-color(body-color);
  }
  h2 {
    color: theme-color(body-color);
  }
  .footer-nav {
    display: flex;
    a {
      padding: 1em 1.5em;
        color: theme-color(body-color);
      &:hover {
        color: theme-color(body-color);
      }
    }
  }
  p {
    padding-top: 1em;
    text-align: left;
  }
}
