label {
  display: block;
  margin-bottom: 0;
  font-size: 0.75em;
  font-weight: 700;
  margin-top: 1.5em;
}
img.profile-pic {
  border-radius: 50%;
  max-width: 100%;
}
.date-range {
  button {
    background: theme-color("dark-blue");
    color: #fff;
    background: #282b67;
    color: #fff;
    border: 0 solid transparent;
  }
  button:disabled {
    background-color: theme-color("primary");
  }
}
label.bring-close {
}
span.editname {
  width: 20em;
  display: inline-block;
  margin-right: 1em;
  max-width: 80%;
  input {
    width: 100%;
    padding: 0.5em 1em;
  }
}
.bankAccountRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 30em;
  h2 {
    width: 100%;
  }
  button.btn.btn-primary {
    margin-top: 0;
    font-size: 80%;
  }
}
