h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Oswald", sans-serif;
  font-weight: 900;
}
.small {
  font-size: 50%;
  &.bottom {
    display: block;
  }
  &.top {
  }
}
body {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
}
h1,
.h1 {
  @media screen and (min-width: 600px) {
    font-size: 200%;
  }
  font-size: 150%;
}
h2,
.h2 {
  @media screen and (min-width: 600px) {
    font-size: 175%;
  }
  font-size: 125%;
}
table {
  font-family: "Oswald", sans-serf;
  .MuiTableCell-root {
    font-family: "Oswald", sans-serf;
  }
}
h3,
.h3 {
  font-size: 1.5em;
}
.greenText {
  color: theme-color("primary");
}
.blueText {
  color: theme-color("secondary");
}
body {
  font-size: 1em;
  @media screen and (min-width: 1200px) {
    font-size: 125%;
  }
}

.firstLetter:first-letter {
  font-size: 0.5em;
  vertical-align: super;
}
.large-number,
.largeNumber {
  @media screen and (max-width: 600px) {
    font-size: 125% !important;
  }
}
.link{
  color:theme-color("primary");
  font-size: 75%;
  font-weight:400;
}
