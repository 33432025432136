.mr-auto.navbar-nav,footer .m-auto.footer-nav {
    display:none;
}
.bankAccounts {
    margin: 0 auto;
    max-width: 30em;
    .text-center{
      button.btn-primary{
      background:transparent;
      color:theme-color(primary);
      font-size:1.35em;
      box-shadow:0 0 0 rgba(0,0,0,0)
    }
  }
    .bankAccountRow button.btn-primary{
      width:auto;
      max-width:10em;
    }
}
.bankAccountRow p{
  margin-bottom:0;
}
@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav {
    width: 100%;
    justify-content: flex-end;
}
}
footer{
  background:none;
  opacity:.75;
  text-align: center;
  p{
    text-align: center;
  }
}
.section.section-header-green {
    text-align: center;
}
.section.center{
  text-align:center;
  margin:0 auto;

  .date{
    max-width:50%;
    margin:0 auto;
    input{
    margin:0 auto;
    display:block;
    text-align: center;
    color: theme-color(primary);
    border-width:0;
    background:transparent;
    font-size:1.5em;
    font-weight:bold;
  }
  .rdtPicker{
    width:100%;
    td.rdtMonth:hover, td.rdtYear:hover, .rdtNext:hover, .rdtPrev:hover{
      background:theme-color(primary);
      color:theme-color(background);
    }
     thead tr:first-of-type th:hover{
       background:theme-color(background);
     }
  }
}
}
.accessList {
  .row{
    margin-left:0;
  }
  button.small-button{
padding:.25em 1.5em;
font-size: 1rem;
margin-left:1em;
display:flex;
justify-content: flex-end;

}
}
label.sectionHeader {
    text-align: center;
    font-size: 1.25em;
}
