.section.table-section.spendStory {
  margin-top: -2em;
    background: theme-color("altBackground");
    margin-bottom:5%;
  .row.barGraph {
    min-height: 15em;
  }
  .largeNumber {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .small.bottom {
      margin-top: -0.25em;
    }
  }
  .rightAlign {
    text-align: right;
    display: flex;
    padding-right: 0;
    justify-content: flex-end;
    .greenBar {
      background: theme-color("primary");
      width: 5em;
      margin-left: 1em;
      align-self: flex-end;
      display: flex;
    }
  }
  .leftAlign {
    display: flex;
    justify-content: flex-start;
    padding-left: 1px;
    .blueBar {
      align-self: flex-end;
      display: flex;
      background: theme-color("secondary");
      width: 5em;
      margin-right: 1em;
    }
  }
}
.flex {
  display: flex;
}

div.spendRangeHeader {
  background: theme-color("altBackground");
  padding: 5% 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 0;
  margin-bottom: 0;
  h1 {
    color: white;
    font-size: 125%;
  }
  .row{
    margin-bottom: 0;
  }
}

div.section-spending-plan {

  padding: 0 0 14%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 0;
  color: white;
  margin-bottom: -7%;
  text-align: center;
}
div.section-spend-plan-footer {
    background: theme-color("altBackground");
  padding: 15% 0 10%;
  margin-top: -10%;
  margin-bottom: -3%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 0;
  color: white;
}
div.section-spend-categories {
  div.circleIcon {
    background-color: theme-color("border");
    width: 4em;
    height: 4em;
    border-radius: 50%;
    margin: -2.5em auto 1.5em;
    display: flex;
    img {
      width: 100%;
      max-width: 2em;
      justify-content: center;
      margin: 0 auto;
      align-self: center;
      max-height: 2em;
    }
  }
  div.card {
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 2.5em;
    background:theme-color("background");
    border:1px solid theme-color("border");
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    h3 {
      font-size: 1rem;
    }
    .largeNumber:first-letter {
      font-size: 0.5em;
      vertical-align: super;
    }
    .small.bottom {
      margin-top: 1rem;
    }
    .card-footer {
      border-top: 0px solid transparent;
      background-color: transparent;
      padding: 0.75em 0;
    }
  }
}
.dark-green-background {
  background: theme-color("dark-green");
}

.brown-yellow-background {
  background: theme-color("brown-yellow");
}
h1.large-number,
h3.large-number,
h2.large-number {
  @media screen and (min-width: 600px) {
    font-size: 275%;
  }
  font-size: 170%;
}
.large-number.surplus {
  @media screen and (min-width: 600px) {
    font-size: 450%;
  }
  font-size: 120%;
}
.darktext {
  color: #444;
}
