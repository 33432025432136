@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap');

$theme-colors: (

  "danger": #ff4136,
  "light-green": #def9c0,
  "green": #11af4a,
  "dark-green": #008b46,
  "darkest-green": #015429,
  "light-blue": #cae2f4,
  "blue": #0086ce,
  "dark-blue": #282b67,
  "brown-yellow": #cc9966,
  "black": #F4F1E9,
  "body": #F4F1E9,
  "error": #FF0000,
  "primary": #ABD976,
  "primaryAccent": #1B4338,
  "secondary": #8CB260,
  "background": #1B4338,
  "gray": #406348,
  "border": #122f2a,
  "white": #F4F1E9,
  "shadow": #171717,
  "success": #1bae9f,
  "altBackground": #183b34,
);

$body-color: #fff;
