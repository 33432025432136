@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .navbar-collapse {
    background:theme-color(altBackground);
    margin-left: -24px;
    margin-right: -23px;
    padding: 1em;
    .navbar-nav {
      a.nav-link {
        color: rgba(0, 0, 0, 0.9);
      }
    }
    .dropdown.nav-item {
      .dropdown-item {
        font-size: 90%;
      }
    }
  }
}
.navbar-nav .dropdown-menu-right {
  display: inherit;
  .dropdown-menu {
    right: 0;
    left: auto;
  }
}
a.active.dropdown-item, a.dropdown-item, a.notButton.dropdown-item {
  color: theme-color("white");
  button{
      color: theme-color("white");
      font-size: 1em;
  }
}
.dropdown:hover > .dropdown-menu {
  display: block;
}
.navbar-nav {
  font-size: 0.9em;
}
.dropdown-item:hover {
  color: white;
  background-color: theme-color("background");
}
span.logo {
  font-weight: 900;

}
button.navbar-toggler.collapsed {
  margin-top: 0;
}
button.navbar-toggler {
  margin-top: 0;
}
.dropdown-menu {
    background: theme-color("border");
    border:1px solid theme-color("border");
}
.headerbackground {
    background: theme-color(altBackground);

}
