.spendPlan {
  .row {
    margin-bottom: 2em;
    label.normalLabel {
      font-size: 1rem;
      font-weight: normal;
      margin-top: -0.5em;
    }
    .blueText.small {
      margin-bottom: 0.25em;
      font-weight: bold;
    }
    .waiwaiEstimate {
      font-size: 80%;
      margin-top: 0.25em;
    }
    h3 {
      margin-top: 1.5em;
    }
  }
}
.contentBottom {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}
.largeNumber {
  font-weight: 900;
  font-size: 200%;
  margin-top: -0.25em;
}
.largestNumber {
  font-size: 325%;
  font-weight: 900;
}
