@import "/scss/variable.scss";
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "/scss/fonts.scss";
@import "/scss/layout.scss";
@import "/scss/buttons.scss";
@import "/scss/forms.scss";
@import "/scss/tables.scss";
@import "/scss/spend-story.scss";
@import "/scss/header.scss";
@import "/scss/footer.scss";
@import "/scss/login.scss";
@import "/scss/page-header.scss";
@import "/scss/profile.scss";
@import "/scss/toastify.scss";
@import "/scss/instructions.scss";
@import "/scss/plan.scss";
@import "/scss/monthlyTransactions.scss";
@import "/scss/easyEdition.scss";
