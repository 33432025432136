.row.monthData {
  padding: 1.5em 0 1em;
  margin:2em -1em 0;
  background: theme-color("border");
  span.label {
    font-size: 55%;
    font-weight: normal;
    display: block;
  }
}
.row.months {
  margin-top: 5%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border:1px solid theme-color(border);
  background: theme-color(altBackground);
  padding: 1.5em 1em;
  &:first-of-type {
    margin-top: 0;
  }
  .card-header {
    width: 100%;
    background: transparent;
    display: flex;
    padding-bottom: 0;
    padding-top: 0;
    justify-content: space-between;
    border-bottom: 0px solid transparent;
    p.transactions {
      margin-bottom: 0;
      margin-top: -0.5em;
    }
    div {
      align-self: top;
    }
    .plusminus {
      color: theme-color("primary");
      .negative {
        color: theme-color("secondary");
      }
    }
  }
  .collapse,
  .collapsing {
    width: 100%;
  }
}
.tabs {
  nav.nav-tabs {
    border-bottom: 0 solid transparent;
    a.nav-item.nav-link {
      color: theme-color("white");
      font-weight: bold;
    }
    a.nav-item.nav-link:hover,
    a.nav-item.nav-link:focus {
      border: 1px solid transparent;
      border-bottom: 4px solid transparent;
    }
    a.nav-item.nav-link.active {
      color: theme-color("primary");
      background: theme-color("background");
      border-bottom: 4px solid theme-color("primary");
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      &:hover {
        border-bottom: 4px solid theme-color("primary");
      }
    }
  }
  .tab-content {
    margin: 5% 0;
  }
  .date.row {
    font-weight: 400;

    border-bottom: 1px solid theme-color("background");
    padding: 0.25em 0;
    margin-bottom: 0.25em;
    margin-top: 1.5em;
    font-size: 66%;
  }
  .TransactionRow.row {
    font-size: 0.8em;
    padding: 0.5em 0;
    font-weight:700;
    margin-bottom: 0;
    .col-6 {
      display: flex;
      flex-direction: column;
    }
    .col-6:nth-child(2) {
      text-align: right;
      .notButton.category {
        button {
          background: transparent;
          color: theme-color("primary");
          text-align: right;
          padding: 0;
          box-shadow: none;
          font-size: 0.7em;
          margin-top: -1.35em;
        }
      }
    }
  }
}
.notButton.menu button.dropdown-toggle.btn.btn-primary {
  background: transparent;
  color: #444;
  padding: 0;
  margin-top: 0.5em;
  &:after {
    border-width: 0;
  }
}
.TopCategories {
  text-align: center;
  h3 {
    margin-left: 0.5em;
    margin-top: 1.5em;
  }
}
h3.transactionsHeader {
  font-size: 1rem;
  margin-left: -1em;
}
span.transactionNumber {
  span.decimals {
    font-size: 0.5em;
    position: absolute;
    margin-top: 0.4em;
    margin-left: 0.15em;
  }
}
.blueBackground {
  h2.month {
    text-align: center;
    padding-top: 1em;
  }
}
.accordion {
  button.notButton {
    margin-top: 0;
  }
}
button.notButton.toggle {
  background: none;
  color: theme-color("primary");
  box-shadow: 0 0 0 0;
  margin-left: -2.75em;
  margin-top: -2em;
}
form.addcash {
  display: flex;
  font-size: 120%;
  padding: 2.5%;
  background: theme-color("background");

  div {
    &.col {
      align-self: flex-end;
    }
    label {
      display: flex;
      flex-direction: column;
      margin-top: 0;
    }
    input {
      padding: 0.5em;
    }
    .btn {
      margin-top: 0;
    }
  }
}
.table-section.section {
  padding: 3% 2%;
  background: theme-color(background);
  margin-bottom: 0;
  margin-top: 1%;
}
.Summary{
  text-align: center;
  background: theme-color("border");
  padding:3em 1em;
}
