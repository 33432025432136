.jss5 {
  overflow: visible;
}
table.table,
table {
  font-size: 80%;
  @media (min-width: 30em) {
    font-size: 100%;
  }
  .MuiTableCell-root {
    border-bottom: 0px solid #fff;
  }
  tr.reviewed {
    background: theme-color("light-green");
    td:nth-child(6) div:nth-child(2) {
      color: theme-color("green");
    }
  }
  tbody.MuiTableBody-root {
    height: auto;
  }
  tr.duplicate {
    background: #f6f6f6;
    td div {
      color: #999;
      text-decoration: line-through;
    }
  }
  tr.totalRow {
    font-size: 1rem;
    font-weight: bold;
    @media (min-width: 30em) {
      font-size: 1.25em;
    }
  }
  thead {
    th {
      font-size: 0.75rem !important;
      font-weight: bold !important;
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      .MuiTableSortLabel-root.MuiTableSortLabel-active {
        margin-top: 7px;
      }
    }
  }
  td {
    border-top: 0px solid transparent;
    input {
      border: none;
      border-bottom: 1px solid #d8d8d8;
      height: 2em;
    }
    input.save-focus {
      border: 1px solid #d8d8d8;
      padding: 0 0.5em;
      @media screen and (max-width: 500px) {
        max-width: 7em;
      }
      &:focus {
        border-color: theme-color("dark-blue");
        background: theme-color("light-blue");
      }
    }

    button.btn.save {
      border-radius: 0;
      margin-left: 0.5em;
    }
  }
  button.dropdown-toggle.btn.btn-primary {
    border: 1px solid #444;
    background: #fff;
    color: #444;
  }
  button.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    margin-top: 0;
    &.secondary {
      border-color: theme-color("dark-green");
      border-width: 0px;
      color: theme-color("dark-green");
      background-color: transparent;
      &:hover {
        background-color: theme-color("dark-green");
        color: #fff;
      }
    }
  }
}
tr.MuiTableRow-root.MuiTableRow-footer td {
  border-bottom: 0px solid #fff;
}

div.MuiPaper-elevation4 {
  box-shadow: 0 0 0 0;
  border: 1px solid #cae2f4;
  margin-top: 0.5em;
  .MuiToolbar-regular,
  tfoot.MuiTableFooter-root {
    background: theme-color("light-blue");
  }
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: transparent;
  &.reviewed {
    background-color: theme-color("light-green");
  }
}
button.MuiButtonBase-root.MuiIconButton-root {
  box-shadow: none;
}
.scroll-wrapper {
  min-height: 700px;

  .jss5 {
    overflow: initial !important;
  }
}
.MuiPaper-root {
  h6.MuiTypography-root {
    display: none;
  }
  .MUIDataTable-responsiveBase-5 {
    overflow: visible;
  }
}
table.table.planned-tables {
  i.dollar-sign {
    margin-left: 0.25em;
    position: absolute;
    margin-top: 0.3em;
    opacity: 0.6;
    @media (min-width: 30em) {
      margin-top: 0.25em;
    }
  }
  input.save-focus.inputDollarSign {
    padding-left: 1em;
  }

  tr {
    border-bottom: 5px solid #fff;
    @media (min-width: 30em) {
      border-bottom: 0 solid #fff;
    }
    td {
      padding: 0 0.25em;
      vertical-align: middle;
      @media (min-width: 30em) {
        padding: 0.75em;
      }
    }
    td:first-child {
      text-align: right;
      @media (min-width: 30em) {
        width: 20%;
      }
    }
    td:last-child {
    }
    td:nth-child(2),
    th:nth-child(2) {
      width: 23%;
      text-align: center;
      button.btn.save {
        position: absolute;
      }
    }
  }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4{
  margin:5% 0;
  background:theme-color("background");
  border:1px solid theme-color("border");
.MuiChip-root.MuiChip-default.MuiChip-deletable{
  background-color: theme-color("primary");
}
.MUIDataTableSearch-searchIcon-104, .MuiInputBase-root{
  color:theme-color("white");
}
.MuiInput-underline:before{
  border-bottom:1px solid theme-color("white");
}
.MuiInput-underline:after{
  border-bottom:2px solid theme-color("primary");
}
  .MuiToolbar-root.MuiToolbar-regular{
    background:theme-color("altBackground");

      .MuiIconButton-root{
          color:theme-color("primary");
          .MuiChip-deleteIcon{
            color:theme-color("altBackground");
          }
      }
  }
  .MUIDataTableFilterList-root-28, .jss111 {
    background: theme-color("altBackground");
    margin: 0;
    padding: 0 1em;
}
  tfoot{
      background:theme-color("altBackground");

      p, input{
        color:theme-color("white");
      }
  }
  .MuiTableCell-body{
    color:theme-color("white");
  }
  th.MuiTableCell-root.MuiTableCell-head{
    background:theme-color("altBackground");
      color:theme-color("white");
      .MUIDataTableHeadCell-sortActive-54, .MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon, span div div, span div div .MuiTableSortLabel-root.MuiTableSortLabel-active{
            color:theme-color("white");
      }
  }
}
.rdtOpen .rdtPicker{
  background:theme-color("background");
  border:1px solid theme-color("border");
  td.rdtActive{
    background:theme-color("primary")
  }
}
.MuiButton-textPrimary{
  color:theme-color("white");
}
.MuiPopover-paper{
   div{
  background:theme-color("border");
}

.MuiGridListTile-root{
max-width:100%;
width:100%;
}
p.MuiTypography-root {
    color: theme-color("white");
}
.MuiGridListTile-tile .MuiGrid-container{
  flex-direction:column;
  .MuiCheckbox-root, input, .MuiFormControlLabel-label{
    color:theme-color("white");
  }
}
svg.MuiSvgIcon-root{
  fill:theme-color("white");
}
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4 > div:nth-of-type(2n){
  background: theme-color("altBackground");
  margin: 0;
  padding: 0 1em;
}
body {
    padding-right: 0 !important;
}
