.full-page {
  height: 100vh;

  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &.spinner {
    align-items: center;
    background: theme-color("background");
  }

  &.mountain-background {
    background: url("../img/background-images/gradient-mountains-login.svg"),
      linear-gradient(
        180deg,
        rgba(0, 122, 170, 1) 0%,
        rgba(123, 194, 254, 1) 100%
      );

    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }
}

.marketing {
  .maincta {
    background: url("../img/homepage/mountains.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    @media (max-width: 1200px) {
      background-position-x: 200%;
    }
    .waiwai-logo {
      padding: 5% 0;
    }
    .btn {
      width: 100%;
    }
  }
  .desktops {
    .row {
      margin: 8% 0 0 0;
      &:last-child {
        margin-bottom: 8%;
      }
      img {
        margin: 5% 0;
      }
    }
  }
  .login-footer {
    background: #75bffa;
    padding-top: 3%;
    padding-bottom: 3%;
    input {
      width: 100%;
      max-width: 20em;
      line-height: 2.5em;
      padding: 0 0.5em;
    }
    .btn {
      width: 100%;
      max-width: 20em;
      &.notButton {
        color: #fff;
        padding-left: 2em;
      }
    }
  }
}
.waiwai-logo {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;

  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-bottom:4%;
  .logo {
    font-weight: 900;
    color: theme-color("white");

  }
}
img {
  max-width: 100%;
}
.container-fluid{
  width:100vw;
  max-width: 100vw;
  height:100vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1{
    text-align: center;
    font-size: 250%;
  }
    .button-box {
      width: 350px;
      max-width: 100%;
      margin: 0 auto;
      button {
        width: 100%;
        text-align: center;
      }
      button.btn-margin.notButton.btn.btn-primary {
    text-align: center;
    width: 100%;
    padding-right: 0;
}
    }
  }
