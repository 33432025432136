div.section-header-green {
  @media screen and (min-width: 768px) {
    min-height: 200px;
  }
  background: theme-color("altBackground");
  padding: 4% 0;

  margin-top: 0;
  min-height: 5em;
  margin-bottom: 4%;
  h1 {
    color: theme-color("white");
    font-size: 2.25em;
  }
}
div.section-header-blue {
  background: theme-color("altBackground");
  padding: 3% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 0;
  h1 {
    color: white;
    font-size: 2.25em;
  }
}
div.section-TempHeader {
  background-color: theme-color("altBackground");
  h1 {
    color: #444;
  }
}
