.btn,
button {
  background-color: theme-color("primary");
  color: theme-color("background");
  padding: 0.5em 2em;
  margin-top: 1.5rem;
  font-weight: 700;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 0 solid transparent;
  &.small {
    font-size: 75%;
  }
  &.secondaryButton {
    background: theme-color("secondary");
  }
}
.btn.blue {
  background-color: theme-color("primary");
}
.btn:focus,
.btn:hover,
button:hover,
button:focus {
  background-color: theme-color("primary");
  color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border: 0 solid transparent;
  outline: 0 solid;
}
a.notButton {
  button,
  button:hover {
    background: none;
    color: theme-color("white");
    border: 0px solid transparent;
    margin-top: 0;
    padding: 0;
  }
  button {
    color: theme-color("white");
  }
  button:hover {
    color: inherit;
  }
}
.notButton {
  button,
  button:hover {
    background: none;
    border: 0px solid transparent;
    padding:0;
    font-size: 75%;
  color: theme-color("primary");
    box-shadow: none;
    font-weight: normal;
  }
}
.secondary-action {
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: theme-color("background");
  background-color: rgba(255,255,255,.4);
  border-color: #eee;
}
button.notButton.btn.btn-primary {
  color: theme-color("primary");
  background-color: transparent;
  border: 0 solid transparent;
  box-shadow: none;
  padding-left: 0;
  color:theme-color("white");
}
button.small-button {
  margin-top: 0;
}
