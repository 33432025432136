.container.instructions {
  img {
    margin-bottom: 2em;
  }
}
.instructions {
  .carousel.slide {
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    margin: 0;
    background-color: theme-color(background);
    color: #444;
    .carousel-inner {
      height: 100vh;
      .carousel-item {
        width: 100%;
        height: 100vh;
        .carousel-caption {
          @media screen and (max-width: 500px) {
            top: 10%;
          }
          color: theme-color("white");
          top: 5%;
          bottom: inherit;
          h3 {
            color: theme-color("white");
          }
        }
      }
    }
    .blueBackground {
      background: url("../img/onboarding/mountainsonboarding.svg"), #dbf0ff;
      padding: 20% 0 10%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      margin-top: 0;
    }

    img {
      max-height: 70vh;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      opacity: 0;
    }
    a.carousel-control-prev {
      display: none;
    }
    .carousel-control-next {
      @media screen and (max-width: 500px) {
        width: 35%;
        bottom: 2rem;
      }
      opacity: 1;
      color: #444;
      span.button-next {
        width: auto;
        height: auto;
        padding: 0.5em 2em;
        background: theme-color("primary");
        color: #fff;
        margin: 0;
        overflow: visible;
        clip: revert;
        position: absolute;
        bottom: 1em;
        font-weight: bold;
      }
    }
    .carousel-indicators li {
      color: #444;
      background-color: #444;
    }
  }
}
