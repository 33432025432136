div.section {
  margin: 5% 0;
}

body {
  overflow: visible !important;
  background:theme-color(background);
  color:theme-color("white");
  div#root {
    position: relative;
    min-height: 100vh;
    .page-cotainer {
      padding-bottom: 0.5em;
    }
  }
}
body .container {
  width: 96% !important;
  @media screen and (min-width: 1200px) {
    max-width: 1400px;
  }
}
.row {
  margin-bottom: 2em;
}
